







































/* eslint-disable class-methods-use-this */
import { Component, Vue, Watch } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { mapGetters } from 'vuex';
import AuthenticationStatus from './authentication/AuthenticationStatus';
import AuthenticationStore from './authentication/AuthenticationStore';
import { showError } from '@/util/Notifier.ts';

@Component({
    computed: mapGetters(['authStatus'])
})
export default class Login extends Vue {
    name: string = '';
    password: string = '';

    authenticationStore = getModule(AuthenticationStore);

    get missingInput() {
        return this.name.length === 0 || this.password.length === 0;
    }

    login() {
        const { name } = this;
        const { password } = this;
        this.authenticationStore.login({ name, password });
    }

    @Watch('authStatus')
    onAuthStatusChanged(status: AuthenticationStatus) {
        if (status === AuthenticationStatus.Error) {
            showError('Login failed');
        } else if (status === AuthenticationStatus.In) {
            if (this.$route.params.back) {
                this.$router.push({ path: this.$route.params.back, query: this.$route.query });
            } else if (this.$route.path !== '/') {
                this.$router.push({ path: '/' });
            }
        }
    }
}
