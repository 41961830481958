


























































































































import { Component, Vue } from 'vue-property-decorator';
import BField from 'buefy/src/components/field/Field.vue';
import { getJobDetails, Job } from './JobClient';
import { showError } from '@/util/Notifier.ts';

@Component({
    components: { BField }
})
export default class JobDetails extends Vue {
    jobID: string = '';
    job: Job = {} as any;

    mounted() {
        this.jobID = this.$route.query.id as string;
        this.loadJobDetails();
    }

    goToSingleView(id: string) {
        this.$router.push({
            query: { id }
        });
        this.jobID = id;
        this.loadJobDetails();
    }

    gotoJobsView() {
        this.$router.push({ path: 'jobs' });
    }

    async loadJobDetails() {
        try {
            this.job = await getJobDetails(this.jobID);
        } catch (e) {
            showError('Failed to retrieve job details from server!', e);
        }
    }
}
