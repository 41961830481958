























import {
    Component, Vue, Prop, Watch
} from 'vue-property-decorator';
import { OCROptions } from './JobParameters';

@Component
export default class OCROptionsForm extends Vue {
    @Prop({ required: true }) initialOptions!: OCROptions;

    options: OCROptions = this.initialOptions;

    @Watch('options')
    onOptionsChanged(options: OCROptions) {
        this.$emit('options-updated', options);
    }
}
