





































import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapActions } from 'vuex';

@Component({
    computed: mapGetters(['isAuthenticated', 'username']),
    methods: {
        ...mapActions(['logout'])
    }
})
export default class Navbar extends Vue { }
