

















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class StartJobButton extends Vue {
    @Prop({ default: false }) disabled!: boolean;

    onClick() {
        this.$emit('click');
    }
}
