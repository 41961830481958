






















import { Component, Vue } from 'vue-property-decorator';

import { startJob } from '../JobClient';
import JobFilesForm from '../JobFilesForm.vue';
import { JobParameters } from '../JobParameters';

import {
    NlpParameters, NlpOptions, initOptions, initNlpTargetFolder
} from './NlpParameters';

import { showError, showSuccess } from '@/util/Notifier.ts';
import ContinueButton from '@/util/ContinueButton.vue';
import StartJobButton from '@/util/StartJobButton.vue';

import NlpOptionsForm from './forms/NlpOptionsForm.vue';

@Component({
    components: {
        JobFilesForm,
        ContinueButton,
        StartJobButton,
        NlpOptionsForm
    }
})
export default class Nlp extends Vue {
    selectedPaths: string[] = [];
    acceptedFileTypes = ".txt,.pdf"
    activeStep: number = 0;
    options: NlpOptions = initOptions();

    continueToJobStart() {
        this.activeStep = 1;
    }

    async startJob() {
        const params = this.buildJobParams();
        try {
            await startJob('nlp', params);
            showSuccess('Job started');
            this.$router.push({ path: '/' });
        } catch (e) {
            showError(e);
        }
    }

    buildJobParams(): NlpParameters {
        return {
            targets: this.selectedPaths.map(
              path => initNlpTargetFolder(path)
            ),
            options: this.options
        };
    }
}
