import { render, staticRenderFns } from "./IngestJournalMetadataForm.vue?vue&type=template&id=ee89a766&scoped=true&"
import script from "./IngestJournalMetadataForm.vue?vue&type=script&lang=ts&"
export * from "./IngestJournalMetadataForm.vue?vue&type=script&lang=ts&"
import style0 from "./IngestJournalMetadataForm.vue?vue&type=style&index=0&id=ee89a766&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee89a766",
  null
  
)

export default component.exports