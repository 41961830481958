













import {
    Component, Vue, Prop, Watch
} from 'vue-property-decorator';
import StagingBrowser from '@/staging/StagingBrowser.vue';

@Component({
    components: {
        StagingBrowser
    }
})
export default class JobFilesForm extends Vue {
    @Prop({ default: () => [] }) private selectedPaths!: string[];
    @Prop() private acceptedFiletypes!: string;
}
