

























import { Component, Vue } from 'vue-property-decorator';
import AuthenticationStatus from './authentication/AuthenticationStatus';
import Navbar from './Navbar.vue';

@Component({
    components: {
        Navbar
    }
})
export default class App extends Vue {
    version: string = process.env.VUE_APP_VERSION;
    githash: string = process.env.VUE_APP_GITHASH;
}
