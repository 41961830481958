





















































































import {
    Component, Vue, Prop, Watch
} from 'vue-property-decorator';
import {
    archiveJob,
    getJobDetails,
    getJobList,
    iconAttributesForState,
    Job
} from './JobClient';
import { showError } from '@/util/Notifier';

@Component({ name: 'JobListEntry' })
export default class JobListEntry extends Vue {
    @Prop(Array) jobIDs!: string[];
    @Prop(Array) activeStates!: string[];
    @Prop(Array) selectedUsers!: string[];
    jobs: Job[] = [];
    isLoading: boolean = true;
    updatePendingJobsInterval: number = 0;
    getChildrenIDs = getChildrenIDs;

    isTopLevel = false;

    mounted() {
        if (this.jobIDs.length === 0) {
            this.isTopLevel = true;

            this.updatePendingJobsInterval = setInterval(() => {
                this.loadJobs();
            }, 10000);
        }

        this.loadJobs();
    }

    beforeDestroy() {
        clearInterval(this.updatePendingJobsInterval);
    }

    @Watch('selectedUsers')
    reloadJobs() {
        this.loadJobs();
    }

    get filteredJobs() {
        if (this.isTopLevel) {
            return this.jobs.filter(job => this.activeStates.includes(job.state));
        }
        return this.jobs;
    }

    async loadJobs() {
        if (this.isTopLevel) {
            this.jobs = await getJobList(this.selectedUsers);
        } else {
            const requests = this.jobIDs.map(id => getJobDetails(id));
            this.jobs = await Promise.all(requests)
                .then(values => values)
                .catch((reason) => {
                    showError(reason, reason);
                    return [];
                });
        }

        this.isLoading = false;
    }

    sortByCreated = sortByCreated;
    sortByUpdated = sortByUpdated;
    compareDates = compareDates;
    iconAttributesForState = iconAttributesForState;

    goToSingleView(id: string) {
        this.$router.push({
            path: 'job',
            query: { id }
        });
    }

    removeJob(id: string) {
        this.$buefy.dialog.confirm({
            message: `Delete Job ${id}?`,
            onConfirm: () => {
                this.jobs = this.jobs.filter(ele => ele.job_id !== id);
                archiveJob(id).catch((response) => {
                    showError('Failed to archive job!', response.error);
                });
            }
        });
    }
}

function getChildrenIDs(children: Job[]) {
    return children.map(child => child.job_id);
}

function sortByUpdated(a: Job, b: Job, isAsc: boolean) {
    const d1 = new Date(a.updated);
    const d2 = new Date(b.updated);
    return compareDates(d1, d2, isAsc);
}

function sortByCreated(a: Job, b: Job, isAsc: boolean) {
    const d1 = new Date(a.created);
    const d2 = new Date(b.created);
    return compareDates(d1, d2, isAsc);
}

function compareDates(a: Date, b: Date, isAsc: boolean) {
    return isAsc ? b.getTime() - a.getTime() : a.getTime() - b.getTime();
}
