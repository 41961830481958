










































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { NlpOptions, formatDCTString } from "../NlpParameters";

@Component
export default class NlpOptionsForm extends Vue {
  @Prop({ required: true }) initialOptions!: NlpOptions;

  options: NlpOptions = this.initialOptions;

  langs = [
    { short: "en", long: "English" },
    { short: "fr", long: "French" },
    { short: "de", long: "German" },
    { short: "it", long: "Italian" },
    { short: "es", long: "Spanish" }
  ];

  // two booleans to track whether pdfs and/or texts are expected
  input_txt = true
  input_pdf = false

  // initializes the datepicker field with a date string like "1970-01-01"
  dct_field: Date = new Date(this.options.document_creation_time);

  @Watch("options.lang")
  @Watch("options.read_dct_from_metadata")
  opOptionsChanged() {
    this.signalOptionsChanged();
  }

  @Watch("input_txt")
  @Watch("input_pdf")
  onConvertOptionChanged() {
    const exts = [];
    if (this.input_txt) exts.push("txt");
    if (this.input_pdf) exts.push("pdf");
    this.options.extensions = exts;
    this.signalOptionsChanged();
  }


  @Watch("dct_field")
  onDctFieldChanged(dct_field: Date) {
    this.options.document_creation_time = formatDCTString(dct_field);
    this.signalOptionsChanged();
  }

  signalOptionsChanged() {
    this.$emit("options-updated", this.options);
  }
}
