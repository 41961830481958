



















































































































import {
    Component, Prop, Vue
} from 'vue-property-decorator';
import { showError } from '@/util/Notifier.ts';
import {
    getStagingFiles,
    deleteFileFromStaging,
    createFolderInStaging,
    StagingNode,
    moveInStaging,
    StagingDirectoryContents,
    getVisibleAndSortedContents,
    JobInfo
} from './StagingClient';
import StagingBrowserNav from './StagingBrowserNav.vue';
import StagingBrowserUpload from './StagingBrowserUpload.vue';
import StagingBrowserFolderSelection from './StagingBrowserFolderSelection.vue';

@Component({
    components: {
        StagingBrowserNav,
        StagingBrowserUpload,
        StagingBrowserFolderSelection
    }
})
export default class StagingBrowser extends Vue {
    @Prop({ required: true }) selectedPaths!: string[];
    @Prop({ default: '' }) acceptedFiletypes!: string;

    operationInProgress: boolean = false;
    workingDirectory: string = '';
    stagingFiles: StagingDirectoryContents = {};
    filesToShow: StagingNode[] = [];
    showCompleted: boolean = false;
    showFailed: boolean = true;

    get checkedFiles(): StagingNode[] {
        return this.filesToShow.filter((file) => {
            const path = getFilePath(this.workingDirectory, file.name);
            return this.selectedPaths.includes(path);
        });
    }

    mounted() {
        this.fetchFiles();
    }

    async onCheck(checkedFiles: StagingNode[]): Promise<void> {
        const paths = checkedFiles.map(file => getFilePath(this.workingDirectory, file.name));
        this.$emit('update:selected-paths', paths);
    }

    createFolder(): void {
        this.$buefy.dialog.prompt({
            message: `Enter the folder name or path`,
            inputAttrs: {
                maxlength: 100
            },
            onConfirm: (folderName) => {
                createFolderInStaging(
                    getFilePath(this.workingDirectory, folderName)
                )
                    .then(() => this.fetchFiles())
                    .catch(e => showError('Failed to create folder!', e));
            }
        });
    }

    async fetchFiles(): Promise<void> {
        try {
            this.operationInProgress = true;
            this.stagingFiles = await getStagingFiles(this.workingDirectory);
            this.filesToShow = getVisibleAndSortedContents(this.stagingFiles);

            this.$emit('files-selected', []);
            this.operationInProgress = false;
        } catch (e) {
            showError('Failed to retrieve file list from server!', e);
        }
    }

    fileClicked(file: StagingNode) {
        if (file.type === 'directory') {
            if (this.workingDirectory) {
                this.openFolder(`${this.workingDirectory}/${file.name}`);
            } else {
                this.openFolder(file.name);
            }
        }
    }

    openFolder(path: string) {
        this.workingDirectory = path;
        this.fetchFiles();
    }

    showDeleteDialogForItem(file: StagingNode) {
        this.onCheck([file]).then(() => {
            this.showDeleteDialog();
        });
    }

    showDeleteDialog() {
        this.$buefy.dialog.confirm({
            message: `Delete ${this.checkedFiles.length} items?`,
            onConfirm: this.deleteSelected
        });
    }

    deleteSelected() {
        this.operationInProgress = true;
        const deletions = this.checkedFiles.map((file) => {
            const filePath: string = getFilePath(
                this.workingDirectory,
                file.name
            );
            return deleteFileFromStaging(filePath).catch(e => showError(`Failed to delete ${file.name}!`, e));
        });
        Promise.all(deletions).then(() => {
            this.$emit('update:selected-paths', []);
            this.fetchFiles();
        });
    }

    showRenameModal(file: StagingNode) {
        this.onCheck([file]).then(() => {
            this.$buefy.dialog.prompt({
                message: `Choose a new name`,
                inputAttrs: {
                    value: file.name,
                    placeholder: 'name',
                    maxlength: 40
                },
                onConfirm: value => this.renameSelected(value)
            });
        });
    }

    renameSelected(value: string) {
        this.operationInProgress = true;
        const moveOperations = this.checkedFiles.map((file) => {
            const sourcePath = getFilePath(this.workingDirectory, file.name);
            return moveInStaging(
                sourcePath,
                getFilePath(this.workingDirectory, value)
            ).catch(e => showError(`Failed to rename ${file.name}!`, e));
        });
        Promise.all(moveOperations).then(() => {
            this.$emit('update:selected-paths', []);
            this.fetchFiles();
        });
    }

    showMoveModalForItem(file: StagingNode) {
        this.onCheck([file]).then(() => {
            this.showMoveModal();
        });
    }

    showMoveModal() {
        this.$buefy.modal.open({
            parent: this,
            component: StagingBrowserFolderSelection,
            events: {
                ok: this.moveSelected
            }
        });
    }

    moveSelected(targetDirectory: string) {
        this.operationInProgress = true;
        const moveOperations = this.checkedFiles.map((file) => {
            const sourcePath = getFilePath(this.workingDirectory, file.name);
            const targetPath = getFilePath(targetDirectory, file.name);
            return moveInStaging(sourcePath, targetPath).catch(e => showError(`Failed to move ${file.name}!`, e));
        });
        Promise.all(moveOperations).then(() => {
            this.$emit('update:selected-paths', []);
            this.fetchFiles();
        });
    }

    getFilesToShow() {
        return this.filesToShow.filter((file) => {
            if (!file.job_info) return true;
            if (!this.showCompleted && file.job_info.status === 'success') return false;
            if (!this.showFailed && file.job_info.status === 'error') return false;

            return true;
        });
    }

    // eslint-disable-next-line class-methods-use-this
    getFileIcon(file: StagingNode) {
        return file.type === 'directory' ? 'folder' : 'file';
    }

    // eslint-disable-next-line class-methods-use-this
    getFileStatusType(file: StagingNode) {
        if (!file.job_info) return '';
        if (file.job_info.status === 'started') return 'is-warning';
        if (file.job_info.status === 'success') return 'is-success';
        return 'is-danger';
    }

    // eslint-disable-next-line class-methods-use-this
    getFileStatusMessage(file: StagingNode) {
        if (!file.job_info) return '';

        const jobInfo = file.job_info as JobInfo;

        // TODO: Avoid strings containing with html code
        if (jobInfo.status === 'started') return `A <a onclick="event.stopPropagation();" href='/job?id=${jobInfo.job_id}' target='_blank'>job</a> is still running.`;

        if (jobInfo.status === 'success' && jobInfo.msg) {
            if (!jobInfo.url || !jobInfo.url_label) return jobInfo.msg;
            return `<a  onclick="event.stopPropagation();" href='${jobInfo.url}' target='_blank'>${jobInfo.url_label}</a>`;
        }

        if (jobInfo.status === 'error' && jobInfo.msg) {
            return `A previous <a onclick="event.stopPropagation();" href='/job?id=${jobInfo.job_id}' target='_blank'>job</a> failed with an error: <pre>${JSON.stringify(jobInfo.msg)}</pre>`;
        }
        return file.job_info;
    }
}

export function getFilePath(baseDir: string, filename: string): string {
    let path = baseDir === '' ? filename : `${baseDir}/${filename}`;

    path = path.replace(/^\//i, '');
    return path;
}

export function getFileName(path: string): string {
    return path.replace(/^.*\//, '');
}

