













































import {
    Component, Prop, Vue
} from 'vue-property-decorator';
import { getStagingFiles, getVisibleAndSortedContents, StagingDirectoryContents } from './StagingClient';
import { showError } from '../util/Notifier';

@Component
export default class StagingBrowserFolderSelection extends Vue {
    private stagingFiles: StagingDirectoryContents = {};
    private directories: string[] = [];
    private workingDirectory: string = '';

    get workingDirectoryArray(): string[] {
        return this.workingDirectory.split('/');
    }

    mounted() {
        this.fetchStagingFiles().then(() => this.openDirectory(''));
    }

    openBreadcrumbEntry(index: number) {
        this.openDirectory(this.workingDirectoryArray.slice(0, index + 1).join('/'));
    }

    async openDirectory(path: string) {
        try {
            this.workingDirectory = path;
            this.stagingFiles = await getStagingFiles(path);
            this.directories = getVisibleAndSortedContents(this.stagingFiles)
                .filter(file => file.type === 'directory')
                .map(file => file.name);
        } catch (e) {
            showError(`Error opening folder '${this.workingDirectory}'`, e);
        }
    }

    async fetchStagingFiles() {
        this.stagingFiles = await getStagingFiles();
    }
}
