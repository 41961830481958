

















import { Component, Vue } from 'vue-property-decorator';
import JobListCompact from '@/job/JobListCompact.vue';
import StartJobSelection from './StartJobSelection.vue';

@Component({
    components: {
        JobListCompact,
        StartJobSelection
    }
})
export default class Dashboard extends Vue {}
