






















































import {
    Component, Prop, Vue
} from 'vue-property-decorator';
import { showError } from '@/util/Notifier';
import { getFilePath } from './StagingBrowser.vue';
import { uploadFileToStaging } from './StagingClient';
import { allowedFileExtensions } from '@/config';

@Component
export default class StagingBrowserUpload extends Vue {
    @Prop({ default: '' }) workingDirectory!: string;
    @Prop({ required: true }) acceptedFiletypes!: string;

    uploadRunning: boolean = false;
    uploadStatus: { [index: string]: Status } = {};
    filesToUpload: File[] = [];

    uploadFiles() {
        this.uploadRunning = true;
        const uploads = this.filesToUpload.map((file: File) => this.uploadFile(file));
        Promise.all(uploads).then(() => {
            this.filesToUpload = [];
            this.uploadRunning = false;
            this.uploadStatus = {};
            this.$emit('upload-finished');
        });
    }

    async uploadFile(file: File) {
        const ext = file.name.split('.').pop() as string;
        if (!allowedFileExtensions.includes(ext)) {
            showError(`Upload of ${file.name} failed<br>Invalid file extension "${ext}"`);
            return;
        }

        const formData = new FormData();
        formData.append('file', file);
        if (this.workingDirectory !== '' && this.workingDirectory.startsWith('/') ) {
            formData.append('target_folder', this.workingDirectory.slice(1));
        }
        const filePath = getFilePath(this.workingDirectory, file.name);
        const status = this.$set(this.uploadStatus, filePath, { percentage: 0, complete: false });
        try {
            const response: any = await uploadFileToStaging(formData,
                this.updateUploadPercentage(filePath));
            this.$set(this.uploadStatus, filePath, { percentage: 100, complete: true });
            if (!response.result[filePath].success) {
                showError(`Upload of ${filePath} failed!<br>${response.result[filePath].error.message}`,
                    response.result[filePath].error.message);
            }
        } catch (e) {
            showError(`Upload of ${filePath} failed!`, e);
        }
    }

    updateUploadPercentage(filePath: string) {
        return (percentage: number) => {
            this.$set(this.uploadStatus, filePath, { percentage, complete: false });
        };
    }

    getValue = getValue;
}

interface Status {
    percentage: number;
    complete: boolean;
}

function getValue(status: Status) {
    if (status.percentage >= 100 && !status.complete) return undefined;
    return status.percentage;
}

