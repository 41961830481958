













































import { Component, Vue } from 'vue-property-decorator';
import { getUsers } from '@/authentication/AuthenticationClient';
import JobListEntry from '@/job/JobListEntry.vue';

@Component({
    components: { JobListEntry }
})
export default class JobList extends Vue {
    showSuccess: boolean = true;
    showFailure: boolean = true;
    showInProgress: boolean = true;
    users: string[] = []
    selectedUsers: string[] = []

    mounted() {
        this.loadUsers();
    }

    async loadUsers() {
        try {
            const response = await getUsers();
            this.users = response.users;
        } catch (e) {
            this.users = [];
        }
    }

    showAll() {
        this.showSuccess = true;
        this.showFailure = true;
        this.showInProgress = true;
    }

    get activeStates() {
        const states = [];
        if (this.showSuccess) {
            states.push('success');
        }
        if (this.showFailure) {
            states.push('failure');
        }
        if (this.showInProgress) {
            states.push('new');
            states.push('started');
        }
        return states;
    }
}

