











import { Vue } from 'vue-property-decorator';

export default class NotFound extends Vue {
}
