































import { Component, Vue } from 'vue-property-decorator';
import store from '@/store';
import { getJobList, iconAttributesForState, Job } from './JobClient';
import { showError } from '@/util/Notifier';

@Component
export default class JobListCompact extends Vue {
    jobList: Job[] = [];

    getUnfinishedJobs() {
        return this.jobList.filter(job => job.state !== 'success');
    }

    iconAttributesForState = iconAttributesForState

    async updateJobList() {
        try {
            this.jobList = await getJobList([store.getters.username]);
        } catch (e) {
            showError('Failed to load job list from server', e);
        }
        this.jobList.reverse();
    }

    mounted() {
        this.updateJobList();
    }
}
