





























































import {
    Component, Prop, Vue
} from 'vue-property-decorator';

@Component
export default class StagingBrowserNav extends Vue {
    @Prop({ default: '' }) workingDirectory!: string;
    @Prop({ default: false }) isFileSelected!: boolean;

    get workingDirectoryArray(): string[] {
        if (this.workingDirectory === '') return [];
        return this.workingDirectory.split('/');
    }

    openFolder(index: number) {
        this.$emit('open-folder', this.workingDirectoryArray.slice(0, index + 1).join('/'));
    }

    openHome() {
        this.$emit('open-folder', '');
    }
}

